<template>
  <div class="perfect-archiving-wrap">
    <div v-if="gameList.length">
      <div class="top-box">
        <div class="title-box">
          <!-- <img src="@/assets/img/wjx.png" alt="" /> 我的收藏 -->
          说明：我在蚂蚁特权客户端收藏的游戏信息
        </div>
        <div class="input-box">
          <el-input placeholder="请输入您想要找的游戏" v-model="keyword" class="input-with-select"
            @keydown.enter.native="handleSearch">
            <el-button slot="append" icon="el-icon-search" type="primary" @click="handleSearch"></el-button>
          </el-input>
        </div>
      </div>

      <div class="main-box">
        <div class="main-item" v-for="(item, index) in gameList" :key="index">
          <router-link :to="`/gameDetail/${item.ProductID}`" class="link-box" v-if="item.gameInfoEntity">
            <span class="icon" v-if="gamesStr.indexOf(item.gameInfoEntity.SteamGameId) > -1">已安装</span>
            <img :src="`${item.gameInfoEntity.PicList[0]}?x-oss-process=image/resize,h_100`" class="game-img" alt />
            <div class="item-wrapper">
              <div class="item-box">
                <h4 v-if="item.gameInfoEntity.Type == 2 || item.gameInfoEntity.Type == 3
                  ">
                  {{ beautySub(item.gameInfoEntity.Name, 4) }}
                  <span class="cloud-archiving">支持存档</span>
                </h4>
                <h4 v-else>{{ item.gameInfoEntity.Name }}</h4>
                <a href="javascript:;" class="btn-experience" @click.stop="collection(item)">取消收藏</a>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <!-- 分页 -->
      <div class="pagination-box">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="pageIndex" :page-size="pageSize" layout="prev, pager, next, jumper" :total="total"
          v-if="total > 0"></el-pagination>
        <!--sizes,  :page-sizes="[8, 16, 32, 64]" -->
      </div>
    </div>

    <div class="no-data" v-if="gameList.length == 0">
      <img src="@/assets/img/nocoll.png" alt="" />
      <p>暂无数据</p>
    </div>
  </div>
</template>

<script>
import { GetGameListNew, UserCollection } from "@/api";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["isLogin", "userInfo"]),
  },
  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      keyword: "",
      gameList: [],
      gamesStr: [],
    };
  },
  created() {
    if (localStorage.getItem("gamesStr")) {
      let str = localStorage.getItem("gamesStr");
      str = str.substr(0, str.length - 1);
      this.gamesStr = str.split(",");
    }
  },
  mounted() {
    if (this.isLogin && this.userInfo) {
      this._GetGameList();
    }
  },
  methods: {
    // 获取游戏列表
    async _GetGameList() {
      let params = {
        source: 3,
        keyword: this.keyword,
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        labels: "",
        platformId: "",
        consumerId: this.userInfo.ConsumerId,
      };

      let res = await GetGameListNew(params);

      if (res.StatusCode == 200) {
        this.gameList = res.Items;
        this.total = res.Total;
      }
    },
    // 收藏/取消收藏
    async collection(item) {
      if (this.isLogin && this.userInfo) {
        this.$confirm("是否确定要取消收藏？", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(async () => {
            let params = {
              consumerId: this.userInfo.ConsumerId,
              gameId: item.GameID,
              isCollection: false,
            };
            let res = await UserCollection(params);
            if (res.StatusCode == 200) {
              this.$message.success("已取消收藏");
              this._GetGameList();
            } else {
              this.$message.error(res.Message);
            }
          })
          .catch((action) => {
            // if (action == "cancel") {
            //   cMethods.JsDownFileAndOpenDir(row.GameArchiveInfoZipURL);
            // }
          });
      } else {
        eventBus.$emit("getQrCode");
      }
    },
    // 一页展示多少条
    handleSizeChange(val) {
      this.pageIndex = 1;
      this.pageSize = val;
      this._GetGameList();
    },
    // 分页
    handleCurrentChange(val) {
      this.pageIndex = val;
      this._GetGameList();
      // this.$statistic(["B", "免费玩页面", "分页点击", "统计量"]);
    },
    beautySub(str, len) {
      var reg = /[\u4e00-\u9fa5]/g, //专业匹配中文
        slice = str.substring(0, len),
        chineseCharNum = ~~(slice.match(reg) && slice.match(reg).length),
        realen = slice.length * 2 - chineseCharNum;
      return str.substr(0, realen) + (realen < str.length ? "..." : "");
    },
    handleSearch() {
      this.pageIndex = 1;
      this._GetGameList();
    },
  },
};
</script>

<style lang="scss" scoped>
.perfect-archiving-wrap {
  width: 100%;

  // padding: 20px;
  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    // padding: 5px;
    // @include item_bg_col();
    .title-box {
      // display: flex;
      // align-items: center;
      // font-size: 22px;
      // @include font_col3();
      display: flex;
      align-items: center;
      font-size: 15px;
      color: #ff3636;

      img {
        margin-right: 5px;
      }

      span {
        margin-left: 20px;
        font-size: 15px;
        color: #999;
      }
    }

    .input-box {
      box-sizing: border-box;

      ::v-deep .el-input-group__append {
        border: none !important;
      }

      .input-with-select {
        .el-input__inner {
          border: 1px solid #e6e6e6;
          background-color: #fff !important;
        }

        ::v-deep .el-input__inner:focus {
          border-color: #ff3636 !important;
        }

        .el-button--primary {
          border-radius: 0px 4px 4px 0;
          color: #fff;
          background-color: #ff3636 !important;
          border-color: #ff3636 !important;
          padding: 13px 20px;
        }
      }
    }
  }

  .main-box {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    .main-item {
      width: 192px;
      height: 170px;
      margin-right: 18px;
      margin-top: 12px;
      position: relative;
      box-sizing: border-box;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
      @include item_bg_col();

      .icon-img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 101;
      }

      a {
        width: 192px;
        height: 170px;
        display: block;
        position: relative;

        .icon {
          position: absolute;
          top: 0;
          right: 0;
          color: #fff;
          padding: 5px;
          font-size: 12px;
          background: red;
        }

        .game-img {
          width: 100%;
          height: 100px;
        }

        .item-wrapper {
          width: 100%;

          .item-box {
            display: flex;
            flex-direction: column;
            padding: 10px;

            h4 {
              text-align: center;
              font-size: 16px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              line-height: 20px;
              @include font_col3();

              .cloud-archiving {
                padding: 5px;
                margin-left: 10px;
                background: $linear-col;
                border-radius: 4px;
                color: $white-col;
                font-size: 12px;
              }
            }

            .btn-experience {
              width: 100%;
              height: 26px;
              line-height: 26px;
              text-align: center;
              margin: 5px auto;
              font-size: 16px;
              color: #999;
              border-radius: 4px;
              font-size: 14px;
              border: 1px solid #ccc;
            }
          }
        }
      }
    }

    .main-item:nth-child(5n) {
      margin-right: 0;
    }

    .main-item:hover {
      transform: translateY(-5px);
      transition: 0.2s ease;
      box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.2);

      h4 {
        color: $red-col;
      }

      .text-hidden {
        display: none;
      }

      .btn-experience {
        border: none !important;
        color: $white-col !important;
        background: $red-col;
      }
    }
  }

  .pagination-box {
    margin-top: 20px;
    text-align: center;
  }

  .no-data {
    min-height: 466px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      margin-top: 10px;
      font-size: 16px;
      @include font_col3();
    }
  }
}
</style>